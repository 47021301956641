import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { CtaBlock } from 'stile-us/src/components/blocks/CtaBlock';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { ButtonLink } from 'stile-shared/src/components/2020/common/ButtonLink';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H3, P, Spacer, Ul } from 'stile-shared/src/components/2020/common/Primitives';
import { Quote } from 'stile-shared/src/components/2020/common/Quote';
import { CONTACT } from 'stile-shared/src/constants';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { StileRoadmapBlock } from 'components/blocks/StileRoadmapBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="A world-class science education, free for every student in Oregon"
      description="Stile is a leading digital learning platform offering science teaching resources to enhance students’ and teachers’ classroom experiences. Learn more here!"
      slug={props.location.pathname}
      uniqueToLocale
    />
  );
}

function HomePage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <HeroBlock
        title="A world-class science education"
        text="Free for every student in Oregon"
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../stile-shared/assets/images/nevada-oregon-homepages/cover.png"
            alt="A group of students performing a lab experiement in a classroom filled with plants"
          />
        }
      />

      <TextBlock>
        <P>
          Stile is free for schools in Oregon until July 2024. As part of the state science
          curriculum adoption process, we will craft Stile into something that speaks uniquely to
          the needs of Oregon educators. To do this, we need to seek advice and listen closely to
          you, Oregon science educators, then update and localize the program to really make it
          sing.
        </P>
        <P>
          Stile has never done anything like this before. But, by making Stile free, we want to
          empower Oregon educators to help us create the curriculum you actually want to teach with.
        </P>
      </TextBlock>

      <TextBlock title="The NGSS, customized for Oregon">
        <P>
          You can read everything you need to know about Stile on our{' '}
          <Link openInNewTab url="https://stileeducation.com/us/">
            homepage
          </Link>
          . But we know that one size doesn’t fit all. We’ve customized the Stile Curriculum that
          hundreds of thousands of teachers and students know and love especially for Oregon.
        </P>
      </TextBlock>

      <TeaserBlock
        contentMax="430px"
        variant="beige"
        title="The John Day Fossil Beds in Oregon"
        text="In our Human Impacts on Ecosystems unit, students act as geologists, ecologists, and activists to uncover evidence for how to save coral reefs and prevent a mass extinction. This includes learning about how scientists make inferences about past environments and investigating the processes of fossil formation and dating. Our team customized a lesson on inferences from fossil evidence for our schools in Oregon to include videos and questions about the John Day Fossil Beds."
        image={
          <StaticImage
            src="../../../stile-shared/assets/images/nevada-oregon-homepages/oregon_john_fossil.png"
            alt="Rounded yellow and red rock hills in a grassland area"
          />
        }
      />

      <TextBlock title="Oregon teachers bring science to life with Stile">
        <P>
          Stile’s interactive, customizable teaching platform and student workbooks are already used
          by hundreds of thousands of students every day, making it the number one middle school
          science curriculum in Australia. But Stile is also 100% pure and fresh Next Generation
          Science Standards (NGSS). One of our units even scored in the{' '}
          <Link url="https://www.nextgenscience.org/resources/middle-school-stile-importance-biodiversity">
            top 1% of WestEd submissions
          </Link>
          ! The best bit? Other teachers in Oregon love it.
        </P>
        <Spacer />
        <H3 as="h3">Here’s what some Oregon teachers are saying about Stile:</H3>
        <Quote
          text="Stile has been so amazing! It really is so much more student-centered than our current curriculum that I am almost giddy."
          credit="8th Grade Science Teacher, Reynolds School District"
        />
        <Quote
          text="I have one really smart kid, really auditory but reads at a first-grade level. He loves the word-by-word highlighting and audio support. With Stile, he can take the quiz all by himself now! I also have one student who just arrived who only speaks Spanish. This makes it easier for her. It’s the part I love best about Stile too."
          credit="6th Grade Science Teacher, Gladstone School District"
        />
        <Quote
          text="They actually have to work. Stile is helping me keep them accountable, get them more independent and ready for high school."
          credit="7th Grade Science Teacher, Gladstone School District"
        />
      </TextBlock>

      <TextBlock variant="dark" title="What Oregon teachers get">
        <Ul>
          <li>
            A beautiful, award-winning, NGSS-aligned library of phenomena-based lessons, lab
            activities, simulations, student investigations, escape rooms, science news, and
            Socratic Seminars — ready to teach as part of a unit storyline or as a standalone bite.
          </li>
          <li>Student data at your fingertips and the ability to provide on-the-spot feedback.</li>
          <li>
            Enhanced student collaboration and a streamlined feedback cycle through Stile’s
            state-of-the-art interactive platform. Also, it’s the opposite of janky. Stile’s
            software has been battle-tested by hundreds of students over more than a decade.
          </li>
          <li>
            The chance to sculpt the curriculum you have always dreamed of teaching so that it is
            relevant and optimized for all students and teachers in Oregon.
          </li>
        </Ul>
        <P>
          And that’s just the tip of the iceberg… there’s much more to discover below the surface.
        </P>
      </TextBlock>

      <StileRoadmapBlock />

      <TextBlock title="Customized to your goals" variant="beige">
        <P>
          Any curious teacher can use Stile’s digital resources in their classrooms for free until
          July 2024, but that’s just the tip of the iceberg when it comes to the full Stile
          experience.
        </P>

        <P>
          Formal pilots, customized for your district lay the groundwork for adoption as a core
          resource.
        </P>
      </TextBlock>

      <TextBlock title="Still have questions?">
        <P>
          We’ve heard them all! Click the FAQ button to download a PDF containing detailed answers
          to all your questions about the Oregon adoption process with Stile. Any further questions?
          Send us an email at <Link url={CONTACT.us.email.url}>{CONTACT.us.email.text}</Link>.
        </P>

        <ButtonLink url="https://stileapp.com/go/Oregon_Adoption_FAQs">
          Oregon adoption FAQs
        </ButtonLink>
      </TextBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default HomePage;
