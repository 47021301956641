import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { LessonGrid } from '../../common/LessonGrid';
import { H2, P, Section } from '../../common/Primitives';
import { Testimonial } from '../../common/Testimonial';
import { VideoModalLauncher } from '../../common/VideoModalLauncher';
import { Center } from '../../layout/Center';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { FullWidthBackground } from '../../layout/FullWidthBackground';
import { Media } from '../../layout/Media';
import { Row } from '../../layout/Row';
import { Sidebar } from '../../layout/Sidebar';

type ContainerProps = React.ComponentProps<typeof FullWidthBackground>;

type ContentProps = {
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4';
  title?: string;
  /** If text is more complicated than plain text, pass as children instead */
  text?: string;
  button?: React.ReactNode;
  testimonial?: React.ComponentProps<typeof Testimonial>;
};

type TeaserBlockProps = ContainerProps &
  ContentProps & {
    variant?: TeaserBlockVariant;
    contentSide?: React.ComponentProps<typeof Sidebar>['side'];
    contentMin?: React.ComponentProps<typeof Sidebar>['contentMin'];
    contentMax?: React.ComponentProps<typeof Clamp>['max'];
    asideMax?: React.ComponentProps<typeof Clamp>['max'];
    sideWidth?: React.ComponentProps<typeof Sidebar>['sideWidth'];
    asideWidth?: React.ComponentProps<typeof Sidebar>['sideWidth'];
    image?: React.ReactNode;
    video?: React.ComponentProps<typeof VideoModalLauncher>;
    lessons?: React.ComponentProps<typeof LessonGrid>['lessons'];
  };

type TeaserBlockVariant = 'inherit' | 'dark' | 'beige';

export function TeaserBlock({
  variant = 'inherit',
  textAlign = 'left',
  contentSide = 'left',
  sideWidth = '450px',
  asideWidth = '550px',
  asideMax,
  contentMin = '45%',
  contentMax,
  headingLevel = 'h2',
  title,
  text,
  button,
  testimonial,
  image,
  video,
  lessons,
  children,
  ...props
}: TeaserBlockProps) {
  const Container = variant !== 'inherit' ? FullWidthBackground : Section;
  let containerProps: React.ComponentProps<typeof FullWidthBackground> = {};
  const innerContainerProps: React.ComponentProps<typeof Center> =
    variant !== 'inherit' ? { gutters: theme.space.gutters } : {};

  if (variant === 'beige') {
    containerProps = { secondaryColor: theme.colors.beige };
  } else if (variant === 'dark') {
    containerProps = { invertColors: true };
  }

  const asideClampMax = asideMax || (video || contentMax ? theme.space.measure : asideWidth);

  const aside = video ? (
    <VideoModalLauncher {...video} />
  ) : lessons ? (
    <LessonGrid lessons={lessons} />
  ) : (
    image
  );

  return (
    <Container forwardedAs="section" textAlign={textAlign} {...props} {...containerProps}>
      <Media lessThan="m">
        <Center max={theme.space.measure} intrinsic>
          <Column space={theme.space.l}>
            <Column space={theme.space.m}>
              {title && <H2 as={headingLevel}>{title}</H2>}
              {text ? <P>{text}</P> : children}
            </Column>

            <Center max={asideClampMax}>{aside}</Center>

            {testimonial ? (
              <Column space={theme.space.m}>
                <Testimonial {...testimonial} />
                {button}
              </Column>
            ) : (
              button && <Row justify="center">{button}</Row>
            )}
          </Column>
        </Center>
      </Media>

      <Media greaterThan="s">
        <Center {...innerContainerProps}>
          <Sidebar
            side={contentSide}
            sideWidth={video ? '350px' : sideWidth}
            contentMin={video ? 0 : contentMin}
            space={theme.space.l}
            justify="center"
            align="center"
          >
            {contentSide === 'right' && <Clamp max={asideClampMax}>{aside}</Clamp>}

            <Clamp max={contentMax}>
              <Column space={theme.space.l} align="center">
                <Column space={theme.space.xs}>
                  {title && <H2 as={headingLevel}>{title}</H2>}
                  {text ? <P>{text}</P> : children}
                </Column>

                {(button || testimonial) && (
                  <Column space={theme.space.l} alignSelf="flex-start">
                    {button}
                    {testimonial && <Testimonial {...testimonial} />}
                  </Column>
                )}
              </Column>
            </Clamp>

            {contentSide === 'left' && <Clamp max={asideClampMax}>{aside}</Clamp>}
          </Sidebar>
        </Center>
      </Media>
    </Container>
  );
}
