import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

type FrameProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /** Frame / crop content */
    aspectRatio?: string;
  };

/**
 * Displays content clamping height based on a given aspect ratio.
 * Mostly used to frame images and video.
 */
export const Frame = ({ aspectRatio = '16:9', children, ...props }: FrameProps) => {
  const aspect = aspectRatio.split(':');
  const denominator = aspect[0];
  const numerator = aspect[1];

  return (
    <FrameContainer denominator={denominator} numerator={numerator} {...props}>
      {children}
    </FrameContainer>
  );
};

type FrameContainerProps = {
  numerator: string;
  denominator: string;
};

const FrameContainer = styled.div<FrameContainerProps>(
  ({ denominator, numerator }) => css`
    padding-bottom: calc(${numerator} / ${denominator} * 100%);
    position: relative;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      overflow: hidden;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    > img,
    > video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  `
);
