import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type SidebarProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /* The minimum width of the content element in the horizontal configuration,
    be wary of using high non-percentage units as it can force overflow on small devices.
    E.g. contentMin="450px" will enforce 450px even at 300px device size.
    Prefer percentage widths. */
    contentMin?: React.CSSProperties['minWidth'];
    /* Which element to treat as the sidebar */
    side?: 'left' | 'right';
    /* Represents the width of the sidebar when adjacent.
    If not set it defaults to the sidebar's content width */
    sideWidth?: React.CSSProperties['flexBasis'];
    /* Gap between elements */
    space?: React.CSSProperties['gap'];
    /* Align items */
    align?: React.CSSProperties['alignItems'];
    /* Justify content */
    justify?: React.CSSProperties['justifyContent'];
  };

/**
 * Displays children in a horizontal or vertical layout, dependent on containing width as well as content widths.
 * The narrower of two adjacent elements is displayed as a "sidebar".
 * The sidebar’s width is fixed while the two elements are adjacent, and the non-sidebar takes up the rest of the available space.
 * Where there is enough space, the two elements appear side-by-side.
 * But when the two elements wrap, each takes up 100% of the shared container, in a vertical layout.
 */
export const Sidebar = ({
  contentMin = '45%',
  justify = 'center',
  side = 'left',
  space = theme.space.l,
  sideWidth,
  children,
  ...props
}: SidebarProps) => {
  return (
    <SidebarContainer
      contentMin={contentMin}
      side={side}
      sideWidth={sideWidth}
      space={space}
      justify={justify}
      {...props}
    >
      {children}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div<SidebarProps>(
  ({ contentMin, justify, align, side, sideWidth, space }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${justify};
    align-items: ${align};
    gap: ${space};

    > * {
      /* The width when the sidebar _is_ a sidebar */
      ${sideWidth ? `flex-basis: ${sideWidth};` : ''}
      flex-grow: 1;
    }

    > ${side === 'right' ? ':first-child' : ':last-child'} {
      /* Grow from nothing */
      flex-basis: 0;
      flex-grow: 999;
      /* Wrap when one of the elements hits this */
      min-width: ${contentMin};
    }
  `
);
