import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Icon } from '../../layout/Icon';
import { Imposter } from '../../layout/Imposter';

import { useModal } from '../Modal';
import { VideoModal } from './VideoModal';

export type VideoModalLauncherProps = Omit<
  React.ComponentProps<typeof VideoModal>,
  'isOpen' | 'closeModal'
> & {
  text: string;
  coverImage: React.ReactNode;
};

/**
 * Pass the cover image as children
 */
export const VideoModalLauncher = ({
  text,
  coverImage,
  url,
  ...props
}: VideoModalLauncherProps) => {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <>
      <Preview onClick={openModal}>
        {coverImage}
        <Imposter>
          <PlayVideoButton text={text} />
        </Imposter>
      </Preview>

      <VideoModal {...props} url={url} isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export const PlayVideoButton = ({ text, ...props }: { text?: string }) => {
  return (
    <FloatingButton {...props}>
      <Icon iconId="play" iconSize="fontSize" text={text} />
    </FloatingButton>
  );
};

const FloatingButton = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.dark};
  background: white;
  opacity: 0.9;
  border-radius: 193px;
  height: 48px;
  padding: 0 24px;
  width: fit-content;
  white-space: nowrap;
  font-weight: 500;
`;

const Preview = styled.button`
  position: relative;
  overflow: hidden;
  max-width: none;

  width: 100%;
  height: 100%;

  border-radius: ${theme.borderRadius.m};
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 1px -1px 5px rgba(0, 0, 0, 0.15);

  cursor: pointer;

  .gatsby-image-wrapper {
    transition: filter ${theme.transitions.long};
  }

  & ${FloatingButton} {
    transition: opacity ${theme.transitions.short};
  }

  &:hover {
    .gatsby-image-wrapper {
      filter: blur(1px);
    }

    & ${FloatingButton} {
      opacity: 1;
    }
  }

  &:focus-visible {
    border: 2px solid ${theme.colors.green2};
  }
`;
