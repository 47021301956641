import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';

import { BlockQuote, Text } from '../Primitives';

type TestimonialProps = React.ComponentProps<typeof Column> & {
  text: string;
  author: string;
  origin?: string;
};

export function Testimonial({ text, author, origin, ...props }: TestimonialProps) {
  return (
    <Column as="figure" space={theme.space.xxs} {...props}>
      <BlockQuote size="s" italic>
        {text}
      </BlockQuote>

      <Text as="figcaption" size="s">
        <Text as="cite" size="s" bold>
          {author}
        </Text>
        {origin && (
          <>
            ,
            <br />
            {origin}
          </>
        )}
      </Text>
    </Column>
  );
}
