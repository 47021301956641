import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type ImposterProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /* Whether the element is allowed to break out of the container over which it is positioned */
    isBreakout?: boolean;
    /* Whether to position the element relative to the viewport */
    isFixed?: boolean;
    /* The minimum space between the element and the inside edges of the positioning container over which it is placed (if breakout is not applied) */
    space?: React.CSSProperties['margin'];
  };

/**
 * Horizontally / vertically center an element, over its parent or the viewport.
 * An ancestor with a positioning value of `relative` or `absolute` must be provided unless `isFixed` is true.
 * The ancestor becomes the “positioning container” over which the Imposter element is positioned.
 */
export const Imposter = ({
  isBreakout = false,
  isFixed = false,
  space = theme.space.s,
  children,
  ...props
}: ImposterProps) => {
  return (
    <ImposterContainer isBreakout={isBreakout} isFixed={isFixed} space={space} {...props}>
      {children}
    </ImposterContainer>
  );
};

const isBreakoutStyles = (space?: ImposterProps['space']) => css`
  margin: ${space};
  max-height: calc(100% - (${space} * 2));
  max-width: calc(100% - (${space} * 2));
  overflow: auto;
`;

const ImposterContainer = styled.div<ImposterProps>(
  ({ isBreakout, isFixed, space }) => css`
    position: ${isFixed ? 'fixed' : 'absolute'};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${isBreakout ? isBreakoutStyles(space) : ''}
  `
);
