import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';

import { BlockQuote, Text } from '../Primitives';

type Quote = React.ComponentProps<typeof Column> & {
  text: string;
  credit: string;
};

export function Quote({ text, credit, ...props }: Quote) {
  return (
    <Container forwardedAs="figure" space={theme.space.xxs} {...props}>
      <BlockQuote italic>{text}</BlockQuote>

      <figcaption>
        <Text as="cite" bold>
          — {credit}
        </Text>
      </figcaption>
    </Container>
  );
}

const Container = styled(Column)`
  border-left-style: solid;
  border-left-width: ${theme.space.xxxs};
  padding-left: ${theme.space.s};
  border-left-color: ${theme.colors.brand};
`;
