import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';

import { Link } from '../Link';
import { Text } from '../Primitives';

type LessonCardProps = React.ComponentProps<typeof Column> & {
  category?: string;
  title: string;
  url?: string;
  image: React.ReactNode;
  textAlign?: React.CSSProperties['textAlign'];
};

export const LessonCard = ({
  category,
  title,
  image,
  url,
  textAlign,
  ...props
}: LessonCardProps) => {
  return (
    <Column space={theme.space.xxxs} {...props}>
      <Column space={0}>
        {image}
        <Text as="h3" size="s" textAlign={textAlign} semiBold smallCaps>
          {category}
        </Text>
      </Column>

      <Column space={0}>
        <Text as="h4" size="m" textAlign={textAlign} bold>
          {title}
        </Text>
        {url && (
          <Text size="s">
            <Link url={url}>View lesson</Link>
          </Text>
        )}
      </Column>
    </Column>
  );
};
