import * as React from 'react';

import YouTubePlayer, { YouTubePlayerProps } from 'react-player/youtube';

import { theme } from '../../../../styles/2020/theme';

import { Frame } from '../../layout/Frame';

import { Modal } from '../Modal';

export type VideoModalProps = Omit<React.ComponentProps<typeof Modal>, 'label'> &
  YouTubePlayerProps & { url: string };

const videoStyle = {
  borderRadius: theme.borderRadius.s,
  backgroundColor: 'black',
};

export const VideoModal = ({ isOpen, closeModal, url, autoplay = true }: VideoModalProps) => {
  return (
    <Modal label="Video" isOpen={isOpen} closeModal={closeModal} externalCloseButton>
      <Frame>
        <YouTubePlayer
          tabIndex={0}
          url={url}
          width="100%"
          height="100%"
          playing={autoplay}
          playsinline={autoplay}
          style={videoStyle}
          controls={true}
          rel={0}
        />
      </Frame>
    </Modal>
  );
};
