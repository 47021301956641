import * as React from 'react';

import styled, { css } from 'styled-components';

import { isEven } from '../../../../utils/isEven';

import { theme } from '../../../../styles/2020/theme';

import { LessonCard } from './LessonCard';

type LessonGridProps = {
  /* Maximum number of items per row */
  limit?: number;
  /* Gap between elements */
  space?: React.CSSProperties['gap'];
  /* Lessons data */
  lessons: React.ComponentProps<typeof LessonCard>[];
};

export const LessonGrid = ({
  space = theme.space.l,
  limit,
  lessons,
  ...props
}: React.PropsWithChildren<LessonGridProps>) => {
  const rowLimit = limit ?? isEven(lessons.length) ? 2 : 3;

  return (
    <LessonGridContainer space={space} limit={rowLimit} {...props} role="list">
      {lessons.map((lesson) => (
        <LessonCard key={lesson.title} {...lesson} role="listitem" />
      ))}
    </LessonGridContainer>
  );
};

const LessonGridContainer = styled.div<Omit<LessonGridProps, 'lessons'>>(
  ({ limit, space }) => css`
    display: grid;
    gap: ${space};
    grid-template-columns: 1fr;

    @media (min-width: 500px) {
      grid-template-columns: repeat(${limit}, minmax(150px, 380px));
    }
  `
);
